'use client';

import { node, object } from 'prop-types';

import { useCookie, useTld } from '@personly/libs-hooks';
import { SchemesProvider } from '@personly/libs-providers';

const Provider = ({ children, schemes }) => {
  const cookie = useCookie();
  const tld = useTld();

  return (
    <SchemesProvider schemes={schemes} cookie={cookie} tld={tld}>
      {children}
    </SchemesProvider>
  );
};

Provider.propTypes = {
  children: node.isRequired,
  schemes: object.isRequired,
};

export default Provider;
